
import Page from "@/components/Page.vue";
import AppHeader from "@/components/AppHeader.vue";
import useUser from "@/composables/useUser";
import { useGetUserQuery, UserType } from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import useError from "@/composables/useError";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { computed, reactive, toRef, watch } from "vue";
import useUpdateUser from "./User/mutations/useUpdateUser";
import Error from "@/components/Error.vue";
import useToastService from "@/composables/useToastService";
export default {
  components: { Page, AppHeader, Error },
  setup() {
    const { user: profile } = useUser();
    const toastService = useToastService();
    const { result, loading, error } = useGetUserQuery(() => ({
      id: profile.value?.userId ?? "",
    }));

    const user = reactive<UserType>({ roles: [] });

    const v$ = useVuelidate(useValidations(), {
      firstname: toRef(user, "firstname"),
      lastname: toRef(user, "lastname"),
    });

    const updateUserMutation = useUpdateUser(
      profile.value?.organizationId,
      profile.value?.userId ?? "",
      user,
      false
    );

    watch(
      () => useResult(result).value,
      (newValue) => {
        Object.assign(user, newValue);
      },
      { immediate: true }
    );

    updateUserMutation.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Gemt",
        message: `Dine detajler er blevet opdateret`,
      });
    });

    updateUserMutation.onError((error) => {
      toastService.show({
        severity: "error",
        error: useError(error),
      });
    });

    return {
      user,
      error: useError(error),
      loading,
      v$,
      save: () => updateUserMutation.call(),
      actionButtonLoading: computed(() => updateUserMutation.loading.value),
    };
  },
};

function useValidations() {
  return {
    firstname: { required },
    lastname: { required },
  };
}
