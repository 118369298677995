import { UserType, useUpdateUserMutation } from "@/graphql/types";
import gql from "graphql-tag";
import useParentQuery from "./useParentQuery";

gql`
  mutation UpdateUser($input: UpdateUserInputType!) {
    updateUser(input: $input) {
      ...UserParts
      roles {
        name
        application {
          name
        }
      }
    }
  }
`;

export default function (
  organizationId: string,
  userId: string,
  user: UserType,
  fullInput = true
) {
  const mutation = useUpdateUserMutation({});

  function call() {
    mutation.mutate(
      {
        input: fullInput
          ? {
              firstname: user.firstname,
              lastname: user.lastname,
              userId: userId,
              administrator: user.administrator,
              phone: user.phone,
            }
          : {
              userId: userId,
              administrator: user.administrator,
              firstname: user.firstname,
              lastname: user.lastname,
              phone: user.phone,
            },
      },
      {
        update: (cache, { data: updatedUser }) => {
          const parentQuery = useParentQuery(organizationId);
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            data: {
              organization: {
                ...cachedData?.organization,
                users: {
                  count: cachedData?.organization?.users?.count,
                  items: cachedData?.organization?.users?.items?.map(
                    (element) =>
                      element?.userId == updatedUser?.updateUser?.userId
                        ? { ...updatedUser?.updateUser }
                        : element
                  ),
                },
              },
            },
          });
        },
      }
    );
  }

  return {
    call,
    ...mutation,
  };
}
